import { defineStore } from "pinia";
import { useNuxtApp } from "#app";
import { $useFetch } from "~/composables/useFetch";

export const useContactsStore = defineStore("contacts", {
	state: () => ({
		contacts: null,
		noAuthContacts: {},
	}),
	actions: {
		fetchAuthContacts() {
			const { api } = useNuxtApp();
			const contactsStore = useContactsStore();

			return $useFetch(api.contacts, {
				onRequest({ options }) {
					options.method = "GET";
				},
				onResponse({ response }) {
					if (response?.status === 200) {
						contactsStore.contacts = response._data.data;
					}
				},
			});
		},
		fetchNoAuthContacts() {
			const { api } = useNuxtApp();
			const contactsStore = useContactsStore();

			return $useFetch(api.phones, {
				headers: {},
				onRequest({ options }) {
					options.method = "GET";
				},
				onResponse({ response }) {
					if (response?.status === 200) {
						contactsStore.noAuthContacts = response._data.data;
					}
				},
			});
		},
	},
	getters: {
		getContacts() {
			return this.contacts;
		},
		getNoAuthContacts() {
			return this.noAuthContacts;
		},
	},
});
